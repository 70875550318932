<template>
  <div>
    <b-carousel
        id="botonesSuperiores"
        v-model="slide"
        :interval="0"
        controls
        img-height="300"
        style="text-shadow: 1px 1px 2px #333;"
    >
        <b-carousel-slide>
                    <div class="logosSuperiores" v-bind:class="{ 'activo':$route.name=='datosBasicos'}">
                    <div class="imagenDatosBasicos" @click="datosPersonales" ></div>
                    <h5>Datos <br /> Básicos</h5>
                    </div>

                    <div class="logosSuperiores" v-bind:class="{ 'disabled': visitaEnGestion.num_acta==null, 'activo':$route.name=='residuosSolidos'}">
                    <div class="imagenResiduos" @click="residuosSolidos" v-bind:class="{ 'disabled': visitaEnGestion.num_acta==null}"></div>
                    <h5>Residuos <br /> Sólidos</h5>
                    </div>

                    <div class="logosSuperiores" v-bind:class="{ 'disabled': visitaEnGestion.num_acta==null || !validarPeso, 'activo':$route.name=='cierreVisita'}">
                    <div class="imagenCierre" @click="cierreVisita" v-bind:class="{ 'disabled': visitaEnGestion.num_acta==null || !validarPeso}"></div>
                    <h5>Cerrar <br /> Visita</h5>
                    </div>
        </b-carousel-slide>
    </b-carousel>
    <br />
    <br />
    <router-view />
  </div>
</template>

<script>
    import {mapActions, mapState} from 'vuex'
    export default {
        name: "FormularioGestionVisita",
        data(){
            return{
                slide: 0,
            }
        },
        async mounted(){
            this.datosPersonales()
             if(this.visitaEnGestion.id){
                await this.cargarResiduosSolidosById(this.visitaEnGestion.id)
            }
        },
        computed:{
            ...mapState('gestionarVisitas', ['visitaEnGestion', 'residuosSolidosCargado']),

            validarPeso(){
                let validacion

                if(this.residuosSolidosCargado){
                    if(this.residuosSolidosCargado.length>0){
                        validacion = true
                    }else{
                        validacion = false
                    }
                }else{
                    validacion = false
                }
                return validacion
            }
        },
        methods:{
              ...mapActions('gestionarVisitas', ['cargarResiduosSolidosById']),
            datosPersonales(){
                this.$router.push({name: 'datosBasicos'})
            },
            residuosSolidos(){
                if(this.visitaEnGestion.num_acta){
                    this.$router.push({name: 'residuosSolidos'})
                }
            },
            cierreVisita(){
                if(this.visitaEnGestion.num_acta!=null && this.validarPeso){
                    this.$router.push({name: 'cierreVisita'})
                }
            },
        }
    };
</script>

<style scoped>

    .logosSuperiores {
        background: #fff;
        padding: 20px;
        border-radius: 15px;
        box-shadow: 0px 0px 10px #555;
        text-align: center;
        width: 170px;
        height: 170px;
        display: inline-block;
        margin: 8px;
        vertical-align: middle;
    }

    .logosSuperiores .imagenDatosBasicos {
        background: url("../assets/iconosApp.png");
        background-position: -4px -90px;
        height: 66px;
        width: 73px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }
    
    .logosSuperiores .imagenResiduos {
        background: url("../assets/iconosApp.png");
        background-position: -950px -90px;
        height: 66px;
        width: 55px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }
    
    .logosSuperiores .imagenCierre {
        background: url("../assets/iconosApp.png");
        background-position: -147px -162px;
        height: 66px;
        width: 55px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }

    .logosSuperiores h5{
        margin-top: 15px;
        font-size: 18px;
        color: #00763f;
        text-shadow: none;
        font-weight: 600;
    }

    .logosSuperiores .disabled{
        opacity: 0.5;
    }

    .logosSuperiores.disabled{
        background: #efefef;
    }
    
    .logosSuperiores.activo{
        background: #02ed7f;
    }

</style>